import { EVENT_SERVICE } from './../../../providers/service.providers';
import { EventService } from '../../../services/event.service';
import { Component, OnInit, Input, Inject } from '@angular/core';
import { Pass } from '../../../models/Pass';
import { ActivatedRoute } from '@angular/router';
import { EventQueryParameterNames } from '../event.component';
import { LocalizableError } from 'src/app/models/LocalizableError';

@Component({
    selector: 'app-passes',
    templateUrl: './passes.component.html',
    styleUrls: ['./passes.component.scss']
})
export class PassesComponent implements OnInit {
    public isLoading: Boolean;
    public error: LocalizableError;
    public passes: Pass[];
    public selectedPass: Pass;

    private readableEventId: string;

    constructor(
        private route: ActivatedRoute,
        @Inject(EVENT_SERVICE) private eventService: EventService
    ) {}

    ngOnInit() {
        this.route.queryParamMap.subscribe(paramMap => {
            this.readableEventId = paramMap.get(
                EventQueryParameterNames.ReadableEventId
            );
            this.loadPasses();
        });
    }

    private loadPasses(): void {
        this.isLoading = true;
        this.eventService.getPasses(this.readableEventId).subscribe(
            passes => {
                this.passes = passes;

                if (this.passes.length > 0) {
                    this.selectedPass = this.passes[0];
                }

                this.isLoading = false;
            },
            (error: LocalizableError) => this.handleErrorResponse(error)
        );
    }

    private handleErrorResponse(error: LocalizableError) {
        this.error = error;
        this.isLoading = false;
    }
}
